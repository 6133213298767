<template>
  <div class="timeline">
    <template v-for="(v, i) in videos" :key="i">
      <ProgressBar v-if="v.control"
                   :value="v.control.currentTime"
                   :max="v.control.duration"/>
    </template>
  </div>

  <div ref="container" class="videos">
    <video ref="videoEls"
           muted
           playsinline
           :autoplay="v.position === 0"
           v-for="(v, i) in videos" :key="i"
           :controls="v.position === 0"
           @ended.prevent="onVideoEnd(v)"
           @click.prevent="rotateToPosition(v.position)"
           :style="positionStyle(v.position)"
           :class="{ active: v.position === 0 }"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import ProgressBar from "@/components/Theme2/ProgressBar.vue";
import { useSwipe, useElementVisibility, until } from "@vueuse/core";
import { sleep } from "@/utils/generalUtils.js";
import { useVideoControls } from "@/helpers/vueHelpers.js";

const { v1, v2, v3 } = defineProps({ v1: String, v2: String, v3: String });
const container = ref(null);
const videoEls = ref([]);
const videos = ref([
  { src: v1, position: +0, el: null, control: null },
  { src: v2, position: +1, el: null, control: null },
  { src: v3, position: -1, el: null, control: null },
]);
const currentVideo = computed(() => videos.value.find(v => v.position === 0));
const { isSwiping, direction, lengthX } = useSwipe(container);
const moving = ref(false);
const transition = 300;
const containerVisible = useElementVisibility(container);

onMounted(() => {
  videos.value.forEach((v, i) => {
    v.el = videoEls.value[i];
    v.control = useVideoControls(v.el, { src: v.src });
  });

  until(containerVisible).toBeTruthy().then(playActiveVideo);
});

watchEffect(() => {
  if (!moving.value && isSwiping.value && Math.abs(lengthX.value) > 50) {
    if (direction.value === 'left') rotateToRight();
    if (direction.value === 'right') rotateToLeft();
  }
});

function positionStyle(pos) {
  const translateX = `calc(${100 * pos}% + ${20 * pos}px)`;
  const scale = pos === 0 ? 1 : 0.735;
  return {
    zIndex: 100 - Math.abs(pos),
    transform: `translateX(${translateX}) scale(${scale})`,
    transformOrigin: pos < 0 ? 'right center' : 'left center',
    transitionDuration: `${transition}ms`,
  }
}

async function rotateToLeft() {
  moving.value = true;
  videos.value.forEach(r => r.position = r.position < 1 ? r.position + 1 : -1);
  playActiveVideo();
  await sleep(transition);
  moving.value = false;
}

async function rotateToRight() {
  moving.value = true;
  videos.value.forEach(r => r.position = r.position > -1 ? r.position - 1 : 1);
  playActiveVideo();
  await sleep(transition);
  moving.value = false;
}

function rotateToPosition(pos) {
  if (pos > 0) rotateToRight();
  if (pos < 0) rotateToLeft();
}

async function playActiveVideo() {
  currentVideo.value.control.mutedPlay();
  videos.value.filter(v => v !== currentVideo.value).forEach(v => v.control.pause());
}

async function onVideoEnd() {
  rotateToRight();
}
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  progress {
    width: 100%;
    max-width: 200px;
  }

  @include mobile {
    gap: 4px;
    padding: 0 20px;
  }
}

.videos {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  user-select: none;
}

video {
  width: 335px;
  border-radius: 15px;
  box-shadow: 0 4px 12px 0 rgba(29, 71, 79, 0.15);
  position: absolute;
  filter: brightness(0.5);

  @include mobile {
    width: 100%;
    max-width: 80vw;
  }

  &.active {
    position: static;
    filter: brightness(1);
  }
}
</style>