<template>
  <progress v-bind="$attrs"></progress>
</template>

<style lang="scss" scoped>
$progress-radius: 2px;

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;

  height: 4px;
  background: var(--progress-track-color, #30758233);
  border-radius: $progress-radius;
  transition: all 100ms linear;

  &::-webkit-progress-value {
    background: var(--progress-bar-color, $green-300);
    border-radius: $progress-radius;
  }

  &::-webkit-progress-bar {
    border-radius: $progress-radius;
    background: var(--progress-track-color, #30758233);
  }

  &::-moz-progress-bar {
    background: var(--progress-bar-color, $green-300);
    border-radius: $progress-radius;
  }
}
</style>